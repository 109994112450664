<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else>
      <!-- IF: User uploaded file -->
      <div v-if="isUserUploaded" class="content">
        <!-- Header -->
        <div class="row">
          <p class="text-2xl font-bold">{{ form.title }} <span v-if="multiple">{{ !translate ? 'ครั้งที่' : 'No.' }} {{ $route.params.oid }}</span></p>
          <div class="ml-auto flex flex-row">
            <button v-if="can_edit" class="edit-button mr-4" v-on:click="editForm">{{ !translate ? 'แก้ไขข้อมูล' : 'Edit' }}</button>
            <div v-if="role === 'STD'" v-on:click="translate = !translate" class="language-button">{{ !translate ? 'EN' : 'TH' }}</div>
          </div>
        </div>
        <hr>

        <!-- Document -->
        <div class="form-div mt-4">
          <p class="label col-span-2">{{ !translate ? 'ไฟล์เอกสาร' : 'Document file' }}</p>
          <div>
            <!-- View file -->
            <a :href="'https://carbon.it.kmitl.ac.th/pdf/document' + this.path" target="_blank">
              <button class="view-button" style="background-color: #FFC700;">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </button>
            </a>
            <!-- Download file -->
            <button class="download-button mx-2" v-on:click="download()">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="white">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- IF: User used fobi form -->
      <div v-else class="content">
        <!-- Header -->
        <div class="row">
          <p class="text-2xl font-bold">{{ form.title }} <span v-if="multiple">{{ !translate ? 'ครั้งที่' : 'No.' }} {{ $route.params.oid }}</span></p>
          <div class="ml-auto flex flex-row">
            <button v-if="can_edit" class="edit-button mr-4" v-on:click="editForm">{{ !translate ? 'แก้ไขข้อมูล' : 'Edit' }}</button>
            <div v-if="role === 'STD'" v-on:click="translate = !translate" class="language-button">{{ !translate ? 'EN' : 'TH' }}</div>
          </div>
        </div>
        <hr>
        <!-- Form Content -->
        <div v-for="element in form.elements" :key="element.id" class="form-div mt-4">
          <p class="label col-span-2">{{ element.plugin_data.label }}</p>
          <!-- Checkbox -->
          <div v-if="element.type === 'checkbox_select_multiple'" class="col-span-4 row mt-4">
            <div v-for="choice in element.plugin_data.choices.split('\r\n')" :key="choice">
              <input
                type="checkbox"
                :id="element.plugin_data.name + '_' + choice"
                :value="choice.split(',')[0]"
                v-model="data[element.plugin_data.name]"
                class="align-middle"
                disabled
              />
              <label v-if="choice.split(',').length === 1" class="mx-4 align-middle">{{ choice.split(",")[0] }}</label>
              <label v-else class="ml-4 mr-16 align-middle">{{ choice.split(",")[1] }}</label>
            </div>
          </div>

          <!-- VDO -->
          <div v-else-if="form.success_page_title != null && form.success_page_title.split(',').includes('vdo')">
            <a :href="data[element.plugin_data.name]" target="_blank">
              <button class="view-button mr-2" style="background-color: #FFC700;">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </button>
            </a>
          </div>

          <!-- Other -->
          <div v-else class="col-span-4 whitespace-pre-line">{{ data[element.plugin_data.name] != '' ?  data[element.plugin_data.name] : '-' }}</div>
        </div>

        <!-- Form status (Advisor) (Not expired) -->
        <div v-if="!form.expired && role === 'TCH' && status === 'W' && isAdvisor">
          <hr>
          <div class="row mt-6">
            <p class="text-base font-bold">สำหรับอาจารย์ที่ปรึกษา / อาจารย์ที่ปรึกษาร่วม</p>
          </div>

          <!-- Choose status -->
          <div class="form-div mt-4">
            <p class="label col-span-2">สถานะเอกสาร</p>
            <div class="col-span-2">
              <input v-model="advisor_status" type="radio" id="pass" class="align-middle" :value="true">
              <label for="pass" class="ml-4">ผ่าน</label>
            </div>
            <div class="col-span-2">
              <input v-model="advisor_status" type="radio" id="fail" class="align-middle" :value="false">
              <label for="fail" class="ml-4">ไม่ผ่าน</label>
            </div>
          </div><br>
          <div class="-mt-6 mb-4">
            <button v-on:click="checkFormData()" class="submit-button ml-auto" style="max-width: 16rem" :disabled="isSubmitted">ยืนยันการตรวจสอบ</button>
          </div>

          <!-- Warning -->
          <div v-if="submitExpired" class="text-red-500 mt-4">ไม่สามารถส่งข้อมูลได้ เนื่องจากแบบฟอร์มนี้สิ้นสุดระยะเวลาการตรวจสอบข้อมูลแล้ว</div>
        </div>

        <!-- Form status (Advisor) (Expired) -->
        <div v-else-if="form.expired && role === 'TCH' && status === 'W' && isAdvisor">
          <hr>
          <!-- Warning -->
          <div class="text-red-500 mt-4">ไม่สามารถตรวจสอบข้อมูลได้ เนื่องจากแบบฟอร์มนี้สิ้นสุดระยะเวลาการตรวจสอบข้อมูลแล้ว กรุณาติดต่อเจ้าหน้าที่ให้ทำการขยายระยะเวลาในการส่งเอกสาร/กรอกข้อมูล เพื่อที่จะสามารถตรวจสอบข้อมูลได้</div>
        </div>

        <!-- Document -->
        <div v-if="isFobiUploaded">
          <hr>
          <div class="form-div mt-4">
            <p class="label col-span-2">{{ !translate ? 'ไฟล์เอกสาร' : 'Document file' }}</p>
            <div>
              <!-- View file -->
              <a :href="'https://carbon.it.kmitl.ac.th/pdf/document' + this.path" target="_blank">
                <button class="view-button" style="background-color: #FFC700;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </button>
              </a>
              <!-- Download file -->
              <button v-on:click="download()" class="download-button mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import moment from "moment"

export default {
  name: "FormView",
  components: {
    NavBar,
  },
  data() {
    return {
      course: 0,
      form: {
        name: "",
        title: "",
        elements: [],
        expired: false
      },
      data: {},
      post: true,
      exist: true,
      loading: true,
      role: null,
      status: "",
      form_detail: null,
      advisor_status: null,
      project: null,
      multiple: false,
      order: 0,
      isSubmitted: false,
      isUserUploaded: false,
      isFobiUploaded: false,
      isAdvisor: false,
      editable: false,
      can_edit: false,
      translate: false,
      submitExpired: false
    }
  },
  async created() {
    const formId = this.$route.params.fid
    const orderId = (this.$route.params.oid) ? (this.$route.params.oid) - 1 : 0
    this.orderId = orderId
    const formTemplate = await this.$store.dispatch("getFormById", formId)
    this.course = formTemplate.course
    this.form = formTemplate.form_entry_detail
    document.title = this.form.title + " | Carbon"
    this.multiple = formTemplate.multiple
    this.role = this.$store.getters.payload.role

    const formData = await this.$store.dispatch("getFormData", {form_id: this.$route.params.fid, project_id: this.$route.params.pid, order_id: this.orderId}).catch((error) => (this.exist = error))
    this.isUserUploaded = (formData.file != null && formData.data.form_entry == null) ? true : false
    this.isFobiUploaded = (formData.file != null && formData.data.form_entry != null) ? true : false
    const form = await this.$store.dispatch("getFormById", formId)
    this.form_detail = form.form_entry_detail
    let data = formData === false ? {} : formData.data.saved_data_dict

    if (!this.isUserUploaded) {
      for (let id in this.form.elements) {
        const element = this.form.elements[id]
        if (element.type === "checkbox_select_multiple" && typeof data[element.plugin_data.name] === 'string') {
          data[element.plugin_data.name] = data[element.plugin_data.name].split(',')
        } else if (element.type === "date") {
          data[element.plugin_data.name] = moment(new Date(data[element.plugin_data.name])).locale('th').add(543, 'year').format('ll')
        }
      }
    }

    this.data = data
    this.status = formData.status
    this.project = await this.$store.dispatch("getProjectById", this.$route.params.pid)
    this.isAdvisor = this.project.advisors.includes(this.$store.getters.payload.user_id) ? true : false
    this.path = formData.file

    // Check if this form expired
    if ((formTemplate.role == this.role) && (this.role == "STD") || this.role == "TCH"  || this.role == "STF" ) {
      this.form.role = formTemplate.role
      if (new Date(formTemplate.active_date_from) > new Date() || new Date(formTemplate.active_date_to) < new Date()) {
        this.form.expired = true
      } else {
        this.form.expired = false
      }
    }

    // Permission
    if ((formTemplate.role == 'TCH') && (this.role == 'STD') || 
    this.role == 'STD' && formTemplate.role == 'STD' && this.project.members.includes(this.$store.getters.payload.user_id) == false) {
      this.$router.push("/")
    }

    // Check if this form editable
    if (this.form.success_page_title != null && this.form.success_page_title.split(',').includes('editable')) {
      this.editable = true
    }

    // Check if user can edit this form or not (Check role, expired, editable)
    if (this.role == 'STD' && !this.form.expired && form.role == 'STD' && this.editable ||
        this.role == 'TCH' && !this.form.expired && form.role == 'TCH' && this.editable) {
      this.can_edit = true
    }

    this.loading = false
  },
  methods: {
    editForm() {
      if (this.multiple) {
        this.$router.push(`/form/${this.$route.params.fid}/project/${this.$route.params.pid}/${this.$route.params.oid}/edit/`)
      } else {
        this.$router.push(`/form/${this.$route.params.fid}/project/${this.$route.params.pid}/edit/`)
      }
    },
    async download() {
      let filename = this.path.split('/')
      filename = decodeURIComponent(filename[filename.length - 1])
      filename = this.form.title + '-' + filename
      await this.$store.dispatch("downloadFormDataFile", { form_id: this.$route.params.fid, project_id: this.$route.params.pid, order_id: this.orderId, name: filename })
    },
    async checkFormData() {
      const formTemplate = await this.$store.dispatch("getFormById", this.$route.params.fid)

      if (new Date(formTemplate.active_date_from) > new Date() || new Date(formTemplate.active_date_to) < new Date()) {
        this.submitExpired = true
      } else {
        this.submitExpired = false
      }

      if (this.advisor_status !== null) {
        if (!this.isSubmitted) {
          this.isSubmitted = true
          this.loading = true
          if (!this.submitExpired) {
            const success = await this.$store.dispatch("updateFormDataStatusById", { form_id: this.$route.params.fid, project_id: this.$route.params.pid, order_id: this.orderId, status: this.advisor_status })
            if (success) {
              this.$router.push(`/project/${this.$route.params.pid}`)
            }
          }
          this.isSubmitted = false
          this.loading = false
        }
      }
    },
  },
};
</script>